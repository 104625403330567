const m = require("mithril")
const Card = require("./Card.jsx")
const CardList = require("./CardList.jsx")
import { db } from "../db.json"

module.exports = {
    view: function (vnode) {
        let cards = db.filter(x => x.cat == vnode.attrs.cat);
        if (cards.length == 0) {
            // invalid category, go back to home page
            m.route.set('/')
        }
        return (
            <div class="list bg-white">
                Category = {vnode.attrs.cat}, Count = {cards.length}
                <CardList cards={cards} />
            </div>
        )
    }
}
