const m = require("mithril")
const Tag = require("./Tag.jsx")

module.exports = {
    view: function (vnode) {
        let cards = vnode.attrs.cards;
        return (
            <div class="bg-slate-900 place-content-center grid grid-cols-1 gap-8 pt-8 pb-12 2xs:mx-0.5 sm:mx-2 lg:mx-12 rounded-xl">
                {cards.map(c => <div><Tag card={c} /></div>)}
            </div>
        )
    }
}
