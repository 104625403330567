

const m = require("mithril")
import Fragment from "./Fragment.jsx"
import Header from "./Header.jsx"
import Label from "./Label.jsx"
import { db } from "../db.json"

module.exports = {
    view: function (vnode) {
        let cats = [...new Set(db.map(card => card.cat))] // added to create cat links for card page - not sure great idea yet
        let show = db.filter(x => x.id == vnode.attrs.card);
        //let bgcolor = 'bg-teal-600' // /* <!-- 0d9488 teal6 / 0891b2 cyan6 / be185d pink7 / 6b21a8 purple8 / c2410c orange7 --> */
        if (show.length != 1) {
            // invalid card, go back to home page
            // <div class="w-32 {bgcolor} border border-slate-300 rounded-md shadow-sm px-1 pt-1 pb-0.5 text-xs font-light text-gray-500">{show.cat}</div>
            m.route.set('/')
        }
        show = show[0]
        console.log(show)
        return (<div class="m-0 text-center bg-gray-900">
            <Header />

            <div class="cats block bg-gray-900 px-10">
                            <div class="cat-list hidden md:flex pt-1.5 pb-1 px-8 justify-evenly">
                                {cats.map((cat) => (<div>
                                    <m.route.Link class="text-gray-400 hover:text-gray-50 rounded-md hover:border hover:border-slate-50 hover:shadow hover:shadow-cyan-600 px-8 pt-0.5 pb-1 bg-transparent hover:bg-gray-700 text-base font-light tracking-wider" href={`/cat/${cat}`}>{cat}</m.route.Link>
                                </div>))}
                            </div>
                        </div>

            <div class="mt-3 mx-2 xs:mx-6 mb-2 p-1 rounded-lg border-2 border-slate-300 shadow-md hover:shadow-xl">
            <div class="p-1 md:p-2 rounded-lg" style="background-image:linear-gradient(#BBC7D5,#CBD5E1)">
            <div class="px-1.5 xs:px-3 sm:px-2 md:pl-4 md:pr-12 pt-1 pb-8 rounded-lg">

        {/* re-enable with grid instead of hidden*/}    
        <div class="hidden">
            <div class="flex justify-self-center xs:justify-self-start rounded-t-md bg-slate-600 text-xs mt-6 ml-1 xs:ml-12 font-[Montserrat] tracking-wider">
                <div class="pt-1 pb-1 pl-2 pr-0.5 rounded-tl-md text-white">Primary</div>
                <div class="pt-1 pb-1 pr-1.5 pl-1 rounded-tr-md text-white">Takeaway</div>
            </div>
        </div>

        {/* re-enable with flex instead of hidden*/}
        <div class="hidden w-full lg:w-5/6 mx-0 lg:mx-0 overflow-hidden bg-white rounded-lg shadow-md mb-4">
            <div class="flex justify-center pt-3 pl-1 w-12 bg-sky-950">
                <div class="flex flex-row-reverse rounded-lg px-2 items-center space-x-0.5 bg-sky-950">
                    <div class="rounded-full w-1 h-0.5 truncate overflow-hidden ml-0.5"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full w-0.5 h-1.5 truncate overflow-hidden ml-0"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full w-0.5 h-2.5 truncate overflow-hidden ml-0"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full w-0.5 h-3.5 truncate overflow-hidden ml-0"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full w-0.5 h-5 truncate overflow-hidden ml-0"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    </div>
            </div>
            <div class="py-4 pl-1.5 pr-4 xs:px-4">
                <div class="ml-1 mr-2 xs:mx-3">
                    <p class="align-baseline text-center xs:text-left text-lg sm:text-xl bg-white text-gray-700 font-[Montserrat] font-extrabold tracking-wide">{show.note}</p>
                </div>
            </div>
        </div>


        <div class="bg-slate-900 py-0.5 px-1 rounded-lg my-4">
        <div class="flex flex-row pt-4 pl-2 p-1 text-left mt-1 mb-1 rounded-lg w-full bg-gradient-to-r from-slate-900 via-slate-900 to-slate-800 text-gray-700 font-[Montserrat] font-bold tracking-wide 2xl:text-[20px] shadow-md">  
                    
                    <div class="grow-0 flex flex-row-reverse rounded-lg pl-1.5 pr-4 py-0.5 mt-3 mb-auto items-center space-x-0.5 bg-slate-900">
                    <div class="rounded-full truncate overflow-hidden ml-px" style="height: 0.15rem;width: 0.22rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="height: 0.33rem;width: 0.18rem;margin-right: 0.3px"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="height: 0.55rem;width: 0.13rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="height: 0.88rem;width: 0.15rem"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="height: 1.2rem;width: 0.17rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    </div>

                    <div class="grow-0 hidden flex-col rounded-lg pl-2 pr-4 py-0.5 mt-3 mb-auto items-center space-y-0.5 bg-transparent">
                    <div class="rounded-full truncate overflow-hidden" style="width: 0.13rem;height: 0.25rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="width: 0.38rem;height: 0.13rem"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="width: 0.63rem;height: 0.13rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="width: 0.88rem;height: 0.13rem"><Label>{show.typ.slice(1,2)}</Label>
                    </div>
                    <div class="rounded-full truncate overflow-hidden ml-0" style="width: 1.25rem;height: 0.16rem"><Label>{show.cat.slice(1,3)}</Label>
                    </div>
                    </div>

                    <div class="grow pr-3 pt-3 pb-6 bg-gradient-to-r from-slate-900 via-slate-800 to-slate-800 text-slate-50 align-middle items-center">{show.note}</div>
                </div></div>

            
            <div class="grid">
            <div class="flex flex-col w-full justify-self-center 2xs:w-max 2xs:flex-row items-center xs:justify-self-start font-[Montserrat] mt-3 2xs:ml-1 xs:ml-12">
                    <div class="flex flex-row"><div class="text-[10px] w-auto mx-auto 2xs:w-auto font-medium tracking-wider py-1 2xs:py-1.5 px-2 2xs:pr-0.5 xs:pl-4 bg-slate-700 rounded-t-md 2xs:rounded-none 2xs:rounded-tl-md" style="color:#fafafa">{show.sub} via</div><div class="2xs:hidden bg-transparent px-1"></div></div>
                    <div class="flex justify-self-center rounded-t-md 2xs:rounded-none 2xs:rounded-tr-md 2xs:flex-row 2xs:x-space-0 bg-slate-800 2xs:bg-slate-700">
                        <div class="w-48 2xs:w-auto text-[10px] tracking-wider rounded-t-md 2xs:rounded-none 2xs:rounded-tr-md pt-1.5 pb-1.5 pl-3 2xs:pr-1 2xs:pl-0.5 font-medium bg-slate-800 2xs:bg-slate-700 text-white">{show.src}</div>
                        
                        <div><svg xmlns="http://www.w3.org/2000/svg" class="mt-0.5 w-5 h-5 2xs:w-4 pt-0 2xs:pt-0.5 pr-1.5 md:pt-0 md:pr-0.5 md:mr-1 bg-slate-800 2xs:bg-slate-700 rounded-tr-md" fill="none" viewBox="0 0 24 24" stroke="#fff">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg></div></div></div>
            </div>

            <div class="w-full lg:w-5/6 text-[10px] 2xs:text-xs font-medium text-gray-600 rounded-xl transition-colors duration-300 ease-in-out bg-gradient-to-b from-white to-white to-slate-50 hover:bg-gradient-to-t hover:from-slate-100 hover:via-blue-50 hover:to-slate-100 border-2 border-gray-200 hover:border-slate-500 overflow-hidden overflow-ellipsis pr-1 xs:pr-2 shadow-sm shadow-slate-600">

            <div class="mt-1 ml-0.5 w-full truncate overflow-hidden" style="height:0.8px"><Label>{show.cat.slice(1,4)}</Label></div>
                <m.route.Link class="flex whitespace-nowrap overflow-hidden overflow-ellipsis rounded-l-xl py-1 2xs:py-1 pl-1 sm:pl-10 py-1 lg:pt-1.5 lg:pb-0.5" href={`${show.url}`} target="_blank">{show.url.replace(/(^\w+:|^)\/\//,'')}</m.route.Link>
            <div class="mb-1 ml-0.5 md:mb-1 lg:mt-1 w-full truncate overflow-hidden" style="height:1.8px"><Label>{show.cat.slice(1,4)}</Label></div>
            </div>

            
            <div class="grid">
                <div class="flex justify-self-center xs:justify-self-start rounded-t-md bg-slate-700 text-[10px] mt-6 mr-2 xs:ml-12 font-[Montserrat] tracking-wider">
                    <div class="pt-1 pb-1 pl-2 pr-0.5 xs:pl-4 rounded-tl-md text-slate-50">Primary</div>
                    <div class="pt-1 pb-1 pr-1.5 pl-1 xs:pr-4 rounded-tr-md text-slate-50">Labels</div>
                </div>
            </div>

                    <div class="grid"><div class="grid grid-cols-1 gap-4 w-full xs:w-[72%] sm:w-[90%] lg:w-[50%] sm:flex sm:flex-row bg-slate-100 mb-1 space-x-0.5 justify-center justify-items-center xs:justify-start xs:justify-items-start py-4 md:pl-2 border-t border-b md:border-b-0 border-gray-400 md:border-slate-300 shadow-md rounded-md">
                    <div class="sm:flex mx-auto sm:ml-12 space-y-3 sm:space-y-0 sm:space-x-3">
                    <Label size="page-typ">{show.typ}</Label>
                    <Label size="page-cat">{show.cat}</Label>
                    </div>
                </div></div>

            <div class="grid">
                <div class="flex justify-self-center xs:justify-self-start rounded-t-md bg-slate-700 text-[10px] mt-6 mr-2 xs:ml-12 font-[Montserrat] tracking-wider">
                    <div class="pt-1 pb-1 pl-2 pr-0.5 xs:pl-4 rounded-tl-md text-slate-50">Additional</div>
                    <div class="pt-1 pb-1 pr-1.5 pl-1 xs:pr-4 rounded-tr-md text-slate-50">Labels</div>
                </div>
            </div>

                <div class="grid"><div class="grid grid-cols-1 gap-4 w-full xs:w-[72%] sm:w-[48%] lg:w-[30%] bg-slate-100 mb-1 space-x-0.5 justify-center justify-items-center xs:justify-start xs:justify-items-start py-4 md:pl-2 border-t border-b md:border-b-0 border-gray-400 md:border-slate-300 shadow-md rounded-md">
                    <div class="mx-auto sm:ml-12 space-y-3">
                    <Label size="page-tag">Tag One</Label>
                    <Label size="page-tag">Tag Two</Label>
                    <Label size="page-tag">Tag Three</Label>
                    <Label size="page-tag">Tag Etc</Label>
                    </div>
                </div></div>
                <div class="text-xs mt-1 mr-1 font-medium sm:font-bold tracking-wide tracking-wide text-gray-400 whitespace-nowrap overflow-hidden overflow-ellipsis"></div>
                <div class="text-xs mt-12 py-1 text-gray-400 whitespace-nowrap overflow-hidden overflow-ellipsis"> (actual tags for this card - {show.tags}) </div>
            </div></div></div>
            {/*
            <div>{show.url}</div>
            <Card card={show} />
            */}
            <Fragment src="footer" />
        </div>)
    }
}