var m = require("mithril")
import { db } from "../db.json"
import HeaderHero from "./HeaderHero.jsx"
import Fragment from "./Fragment.jsx"
import CardList from "./CardList.jsx"
import Label from "./Label.jsx"

module.exports = {
    view: function () {
        let cats = [...new Set(db.map(card => card.cat))]
        let typs = [...new Set(db.map(card => card.typ))]
        let recent = [...db].sort((a,b) => b.dcr-a.dcr).slice(0,50)

        return (
            // JSX syntax rules require that we return a single element. This
            // empty tag is used as a wrapper that does not impact the final
            // rendered HTML.
            <>
                <div class="cats bg-slate-900"> <HeaderHero />
                        <div class="grid cats mt-2.5 justify-items-center">
                        <div class="cat-list grid grid-cols-1 space-y-2 xs:hidden">
                                {cats.map((cat) => (<div class="mr-1 w-60 2xs:w-68">
                                <div><m.route.Link class="flex place-content-left rounded-md shadow-md border border-gray-500 text-[14px] font-[Montserrat] font-medium tracking-wide text-slate-200 bg-gradient-to-b from-gray-900 via-gray-800 to-gray-800 hover:bg-gradient-to-t hover:from-slate-900 hover:via-slate-800 hover:to-slate-600 hover:text-gray-50 pl-4 py-2" href={`/cat/${cat}`}>
                                    <div class="flex flex-row space-x-3"><div class="mt-1 rounded-sm h-3 w-6 truncate overflow-hidden"><Label>{cat.slice(1,2)}</Label></div><div> {cat}</div></div>
                                    </m.route.Link>
                                </div></div>))}
                            </div>
                            <div class="cat-list hidden xs:grid xs:grid-flow-row-dense xs:grid-cols-2 xs:grid-rows-3 sm:grid-cols-5 sm:grid-rows-1 xs:gap-x-0 gap-y-1 xs:gap-y-2 sm:gap-1 sm:mx-auto md:mx-0">
                                {cats.map((cat) => (<div class="xs:flex">
                                <div><m.route.Link class="flex place-content-left sm:place-content-center md:place-content-left space-y-4 mx-2 sm:mx-0 xs:w-52 sm:mt-2 sm:w-auto md:w-36 lg:w-48 rounded-md shadow-md border border-gray-500 font-[Montserrat] font-normal text-[13px] sm:text-[11px] md:text-[10px] lg:text-[13px] text-white md:text-gray-50 lg:tracking-wider bg-gradient-to-b from-gray-900 via-gray-800 to-gray-800 hover:bg-gradient-to-t hover:from-slate-900 hover:via-slate-800 hover:to-slate-600 hover:text-gray-50 pl-4 sm:px-3 md:pl-2 py-2 xs:py-2 sm:pb-2 sm:pt-1 sm:tracking-wide" href={`/cat/${cat}`}>
                                    <div class="flex flex-row space-x-2 sm:flex-col-reverse md:flex-row sm:space-y-1 sm:space-x-0 md:flex-row md:space-x-2"><div class="mt-0.5 md:mt-1.5 lg:mt-2 rounded-sm h-3 sm:h-0.5 md:h-2.5 lg:h-3 w-6 sm:w-24 md:w-4 truncate overflow-hidden"><Label>{cat.slice(1,2)}</Label></div><div class="sm:self-center md:self-auto"> {cat}</div></div>
                                    </m.route.Link>
                                </div></div>))}
                            </div>
                        </div>
                        <div class="bg-sky-950 rounded-b-md"> <CardList cards={recent} /> </div>
                        <div class=""> <Fragment src="footer" /> </div></div>
            </>
        )
    }
}