const m = require("mithril")
const Card = require("./Card.jsx")

module.exports = {
    view: function (vnode) {
        let cards = vnode.attrs.cards;
        return (
            <div class="bg-slate-900 pt-6 pb-4 my-4 ml-1 mr-0.5 2xs:ml-0 2xs:mr-0 2xs:mx-2 xs:mx-6">
                {cards.map(c => <div><Card card={c} /></div>)}
            </div>
        )
    }
}
