var m = require("mithril")
import { db } from "../db.json"
import HeaderHero from "./HeaderHero.jsx"
import Fragment from "./Fragment.jsx"
import TagList from "./TagList.jsx"

module.exports = {
    view: function () {
        let cats = [...new Set(db.map(card => card.cat))]
        let typs = [...new Set(db.map(card => card.typ))]
        let recent = [...db].sort((a,b) => b.dcr-a.dcr).slice(0,4)

        return (
            // JSX syntax rules require that we return a single element. This
            // empty tag is used as a wrapper that does not impact the final
            // rendered HTML.
            <>
                <div class="cats bg-slate-900"> <HeaderHero />

                        <div class="bg-slate-900">
                        <div class="mt-4 mb-3 text-center text-slate-350 tracking-widest font-semibold font-[Montserrat] text-[11px] xs:text-[13px]">Sunday, December 18th</div>
                        <TagList cards={recent} /> 
                        </div>
                        <div> <Fragment src="footer" /> </div></div>
            </>
        )
    }
}