import m from "mithril";
import Home from "./views/Home.jsx"
import Today from "./views/Today.jsx"
import CardPage from "./views/CardPage.jsx"
import Category from "./views/Category.jsx"
import Static from "./views/Static.jsx"

// These exported functions will be available in HTML as app.start() and
// app.linkto() after the build process.

// start() initializes Mithril and effectively starts the app.
export function start() {
    const root = document.body

    // hack to make mithril and esbuild play nicely
    m.Fragment = { view: vnode => vnode.children };

    // turn off /#!/ on routes
    m.route.prefix = ''

    // setup routing table
    m.route(root, "/", {
        "/": Today,
        "/list": Home,
        "/cat/:cat": Category,
        "/card/:card": CardPage,
        "/about": Static,
        "/aboutnew": Static,
        "/archive": Static,
        "/privacy": Static,
        "/sources": Static,
    })
}

// linkto() is available for static HTML fragments to make efficient links to
// Mithril routes. In static HTML, when you want:
//
//    <a href="/privacy">Privacy Policy</a>
//
// ... write it as:
//
//   <a href="#" onclick="return app.linkto('/privacy')">Privacy Policy</a>
//
// This will prevent the browser from making a round-trip to the server to
// follow a link, improving the perceived performance of the application.

export function linkto(src) {
    console.log("linkto: " + src)
    m.route.set(src)
    window.scrollTo(0, 0)
    return false
}