const m = require("mithril")
import Label from "./Label.jsx"
import TagList from "./TagList.jsx"

module.exports = {
    view: function (vnode) {
        let c = vnode.attrs.card
        return (
            <div class="bg-slate-900 px-1 sm:mx-4 md:ml-8 md:mr-12 lg:mx-12 xl:mx-24">

{/* lg:flex grow? some kind of upvote decoration idk
            <div class="flex flex-row">
            <div class="text-white">X</div>
            <div> */}

            <div class="flex flex-row justify-between" style="margin-bottm: 0.5px;">
            <div>
            {/* t-l-h */}
            <div class="flex flex-row-reverse space-x-0.5 justify-start items-end truncate overflow-hidden" style="margin-left: 4px; margin-bottom: 1px;">
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.5px; margin-left: 2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.20px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.50px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.80px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 6.10px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 6.40px; margin-bottom: 0.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full -rotate-12 truncate overflow-hidden" style="margin-left:2.5px; height: 1.95px;width: 6.70px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div>
            </div>
            {/* t-r-h 
            hidden 2xs show = 6
            */}
            <div class="flex justify-end mr-1 rounded-r-xl">
                  <div class="flex flex-row space-x-0.5 justify-end items-end truncate overflow-hidden" style="padding-right: 1.5px; margin-bottom: 0.7px;">
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="hidden md:flex rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.00px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.20px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.50px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 5.80px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 6.10px; margin-bottom: 0.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 1.95px;width: 6.70px; margin-bottom: 0.6px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                </div>

            <div class="flex flex-row h-36 xs:h-32 sm:h-[7.5rem] md:h-[5.5rem] bg-gradient-to-b from-black via-transparent to-black">

            <div class="flex flex-col justify-between">
            <div>
                {/* t-l-v */}
            <div class="flex rounded-r-xl" style="">
                  <div class="flex flex-col space-y-0.5 items-end truncate overflow-hidden" style="margin-left: 3.2px; margin-bottom: 3px;">
                  <div class="rounded-full truncate overflow-hidden" style="height: 4.6px;width: 3.2px; margin-left: 0.8px; margin-bottom: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.8px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                </div>

                {/* b-l-v */}
                <div>
                <div class="flex rounded-r-xl" style="">
                  <div class="flex flex-col-reverse space-y-0.5 items-end truncate overflow-hidden" style="margin-left: 3.2px; margin-top: 3.5px;">
                  <div class="rounded-full -rotate-12 truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-left: 1.5px; margin-right: 0.3px; margin-top: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-right: 0.5px; margin-bottom: 1.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-right: 0.5px; margin-top: 2.0px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-right: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-right: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-right: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-right: 0.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                </div>
                </div>
                
                <m.route.Link class="w-full px-1 xs:px-0.5 md:px-0 md:pl-1 pb-2 rounded-md border-b bg-gradient-to-t from-slate-100 via-slate-50 to-slate-350 shadow-sm hover:border-gray-50 hover:shadow-xl" href={`/card/${c.id}`}>
                
          <div class="mt-2 2xs:mt-0 xs:mt-1.5 sm:mt-3 font-[Montserrat]">
    



    <div class="flex flex-col 2xs:flex-row sm:flex-col md:flex-row">
        <div class="hidden sm:flex sm:w-1 md:w-1.5 lg:w-[2%] xl:w-[4%]"></div>


    <div class="2xs:mt-4 2xs:w-[72%] xs:pl-1 xs:mt-2 sm:mt-1 xs:grow xs:max-w-[52%] sm:min-w-[80%] sm:mx-auto md:mt-0.5 md:min-w-[55%] md:max-w-[55%] md:ml-2 md:mr-0 md:grow-0 lg:min-w-[50%] lg:max-w-[50%] xl:min-w-[45%] xl:max-w-[45%]">

          <div class="grow px-2 2xs:px-0 2xs:pl-1 xs:w-auto flex flex-col sm:hidden mx-auto">
          <div class="grow xs:w-auto text-center 2xs:px-1 px-3 pt-1.5 pb-1 bg-slate-50 border-t border-x border-slate-550 tracking-widest xs:tracking-wide sm:tracking-widest font-semibold text-slate-900 rounded-t-lg text-[12px] items-end truncate overflow-hidden text-ellipsis ">randomTag {c.cat.slice(0,4)}X</div>
          <div class="grow xs:w-auto text-center px-4 pt-1 pb-0.5 2xs:py-1.5 xs:pb-1 sm:pb-0.5 bg-sky-950 tracking-wider font-medium text-white text-[11px]">{c.sub}</div>
          <div><Label size="tag-cat">{c.cat}</Label></div>
          </div>
          <div class="grow px-2 2xs:px-0 2xs:pl-1 xs:w-auto flex flex-col sm:hidden mx-auto capitalize">
          <div class=""><Label size="tag-sub">{c.typ}</Label></div>
          </div>

          <div class="w-full hidden sm:flex flex-row md:w-auto">
          <div class="w-full text-center rounded-t-lg px-1 pt-1.5 pb-1 bg-slate-50 border-t border-x border-slate-550 tracking-widest font-semibold text-slate-900 text-[13px] md:text-[13px] lg:text-[14px] xl:text-[15px]">randomTag {c.cat.slice(0,4)}X</div>
          </div>
          <div class="w-full hidden sm:flex flex-row">
          <div class="w-1/3"><Label size="tag-sub">{c.typ}</Label></div>
          <div class="w-1/3"><Label size="tag-cat">{c.cat}</Label></div>
          <div class="w-1/3 border-b border-sky-950 text-center rounded-br-lg px-4 pt-1 sm:pt-1.5 bg-sky-950 tracking-wide font-medium text-white text-[11px] sm:text-[11.5px] lg:text-[13px] xl:text-[14px]">{c.sub}</div>

          </div>
    </div>

    <div class="hidden xs:flex xs:w-[2.5%] sm:hidden"></div>     

                

        <div class="2xs:pt-0.5 2xs:w-[28%] xs:pt-8 xs:max-w-[60%] xs:grow sm:grow-0 sm:min-w-[75%] sm:mx-auto sm:pt-2 md:min-w-[43%] md:max-w-[43%] md:pt-0.5 lg:min-w-[48%] lg:max-w-[48%] lg:mx-0 lg:pt-3 xl:min-w-[52%] xl:max-w-[52%] xl:pl-4 xl:pr-12">
                <div class="mt-1.5 pt-1 2xs:mt-0 2xs:mx-3 2xs:pr-3 2xs:mb-1 xs:mx-2 xs:pr-4 sm:pr-0 sm:mx-3.5 sm:mr-4 sm:pt-2 text-center grid grid-cols-3 2xs:grid-cols-1 2xs:grid-rows-3 xs:grid-cols-3 xs:grid-rows-1 font-[Montserrat] justify-items-center 2xs:justify-items-start xs:justify-items-evenly sm:justify-items-center sm:align-top md:space-x-0 lg:space-x-1 lg:mx-0 lg:pl-2 lg:pr-4">


                <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row 2xs:pl-1 xs:pl-0 xs:pr-1 sm:space-x-1 sm:pr-4 md:space-x-0 lg:pr-0 lg:space-x-1 xl:pr-2">
                <div class="flex flex-row space-x-1 md:space-x-0.5">
                <div class="sm:pl-0.5 sm:mb-2 md:pl-1 md:mb-0.5 lg:pl-0 xl:mb-0 xl:mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" stroke-width="1.1" stroke="currentColor" class="w-5 h-5 xs:w-6 2xs:h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></div>
                <div class="text-sm font-semibold 2xs:text-[12.5px] sm:text-[13px] md:text-[14px] xl:text-[15px] 2xs:mt-0.5 xl:mt-1">94%</div>
                </div>
                <div class="sm:mt-0.5 md:mt-0 lg:mt-0.5 xl:mb-0 text-[11px] 2xs:text-[12px] xs:text-[12.5px] sm:text-[13.5px] md:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wide xs:tracking-tight md:tracking-wide xl:tracking-wider font-medium font-[Montserrat]">Beneficial</div>
                </div>
              
                <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row 2xs:pl-1 xs:pl-1 sm:space-x-1 sm:pr-4 xl:pr-6">
                <div class="flex flex-row space-x-1 md:space-x-0.5 md:pl-0.5 lg:pl-0">
                <div class="xs:pl-0.5 md:pl-1.5 xl:mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" fill="#ffe4e6" viewBox="0 0 24 24" stroke-width="1.1" stroke="currentColor" class="w-5 h-5 xs:w-6 2xs:h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg></div>
                <div class="text-sm font-semibold 2xs:text-[12.5px] sm:text-[13px] md:text-[14px] xl:text-[15px] 2xs:mt-0.5 xl:mt-1">31%</div>
                </div>
                <div class="sm:mt-0.5 xl:mt-0.5 text-[11px] 2xs:text-[12px] xs:text-[12.5px] sm:text-[13.5px] md:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wide xs:tracking-tight md:tracking-wide xl:tracking-wider font-medium font-[Montserrat]">Accessible</div>
                </div>

                <div class="flex flex-col sm:flex-row md:flex-col lg:flex-row 2xs:pl-1 xs:pl-3 sm:space-x-1 sm:pr-2 md:pr-4">
                <div class="flex flex-row space-x-1 md:space-x-0.5 md:pl-1 lg:pl-0">
                <div class="xs:pl-0.5 md:pl-1.5 xl:mt-0.5"><svg xmlns="http://www.w3.org/2000/svg" fill="#fef9c3" viewBox="0 0 24 24" stroke-width="1.1" stroke="currentColor" class="w-5 h-5 xs:w-6 2xs:h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                </svg></div>
                <div class="text-sm font-semibold 2xs:text-[12.5px] sm:text-[13px] md:text-[14px] xl:text-[15px] 2xs:mt-0.5 xl:mt-1">58%</div>
                </div>
                <div class="sm:mt-0.5 xl:mt-0.5 text-[11px] 2xs:text-[12px] xs:text-[12.5px] sm:text-[13.5px] md:text-[14px] lg:text-[14px] xl:text-[16px] tracking-wide xs:tracking-tight md:tracking-wide xl:tracking-wider font-medium font-[Montserrat]">Sustainable</div>
                </div>


              </div>
        </div>
</div>              

              </div>

            </m.route.Link>

            <div class="flex flex-col justify-between">
                {/* t-r-v */}
            <div class="flex mr-0.5 pb-0.5 rounded-r-xl" style="background:transparent">
                  <div class="flex flex-col space-y-0.5 items-start truncate overflow-hidden" style="margin-left: 1px;">
                  <div class="rounded-full truncate overflow-hidden" style="height: 4.7px;width: 3.2px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-left: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>

                {/* b-r-v */}
                <div class="flex mr-0.5 pt-1 rounded-r-xl" style="margin-top: 4px;">
                  <div class="flex flex-col-reverse space-y-0.5 items-start truncate overflow-hidden" style="margin-top: 3px; margin-left: 1.5px">
                  <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 3.8px;width: 3.2px; margin-top: 1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.6px;width: 3.2px; margin-left: 0.5px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.4px;width: 3.2px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.2px;width: 3.2px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 3.0px;width: 3.0px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.5px;width: 2.5px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.2px;width: 2.2px; margin-left: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>

                </div>
            
                </div>
            
            <div class="flex flex-row justify-between">
                {/* b-l-h */}
                <div class="flex justify-end mr-1 rounded-r-xl mb-4">
                  <div class="flex flex-row space-x-0.5 justify-end items-start truncate overflow-hidden" style="padding-left: 7px; margin-top: 1px;">
                  <div class="rounded-full rotate-12 truncate overflow-hidden" style="height: 1.95px;width: 4.3px; margin-left: 1px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 4.2px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.1px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 4.0px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 3.9px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.70px;width: 3.8px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.65px;width: 3.7px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.60px;width: 3.6px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.55px;width: 3.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.50px;width: 3.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.45px;width: 3.5px"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 1.40px;width: 3.5px; margin-top: 0.1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>
                {/* b-r-h */}
                <div class="flex justify-end mr-1 rounded-r-xl mb-4">
                  <div class="flex flex-row space-x-0.5 justify-end items-start truncate overflow-hidden" style="padding-right: 3.2px; margin-top: 1px;">      
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.65px;width: 3.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.70px;width: 3.8px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.75px;width: 3.9px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.80px;width: 4.0px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.85px;width: 4.1px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.90px;width: 4.2px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 1.95px;width: 4.3px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full truncate overflow-hidden" style="height: 2.00px;width: 4.4px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="rounded-full -rotate-12 xs:rotate-0 truncate overflow-hidden" style="height: 2.05px;width: 4.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 2.10px;width: 4.6px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full truncate overflow-hidden" style="height: 2.15px;width: 4.7px;"><Label>{c.cat.slice(1,2)}</Label></div>
                  <div class="hidden xs:flex rounded-full -rotate-12 truncate overflow-hidden" style="height: 2.20px;width: 4.8px; margin-bottom: 0.5px;"><Label>{c.cat.slice(1,2)}</Label></div>
                </div></div>

                </div>
            

                
            
                
                
                </div>
        )
    }
}