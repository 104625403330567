const m = require("mithril")
import Hamburger from "./Hamburger.jsx"

module.exports = {
    view: function (vnode) {
        return (<>
            <div class="header">

                <nav class="py-2 md:py-4 bg-gradient-to-b from-slate-900 to-slate-750" style="">
                    <div class="px-4 mx-auto md:px-2 sm:flex sm:grow sm:items-center">

                        <div class="flex justify-between items-center">
                            <a href="#" class="tracking-widest text-gray-100 hover:text-slate-300 ml-3 2xs:ml-1 md:ml-3" onclick="return app.linkto('/')"><span class="text-sm 2xs:text-lg font-[Montserrat] font-semibold text-zinc-100">Nimble Notes</span><span class="ml-1.5 mt-1 text-lg 2xs:text-2xl text-slate-400">୪</span></a>
                            <Hamburger />
                        </div>

                        <div class="hidden flex-col space-y-2 2xs:place-items-end sm:place-items-center mt-4 mb-3 sm:mt-2 sm:mb-1 sm:flex sm:space-y-0 sm:space-x-1 md:space-x-2 sm:flex-row sm:ml-auto sm:mr-2 md:mr-4" id="navbar-collapse">
                            
                            <m.route.Link href="/about" onclick="return app.linkto('/about')" class="2xs:w-72 2xs:mx-auto xs:w-60 xs:mx-0 sm:w-28 pt-1.5 pb-1.5 bg-gradient-to-b from-slate-900 via-sky-950 to-slate-900 border border-slate-500 text-slate-200 text-center text-[13px] font-[Montserrat] font-semibold tracking-wide rounded-lg hover:bg-gradient-to-b hover:from-slate-600 hover:via-transparent hover:to-slate-700 md:hover:to-slate-900 hover:text-slate-200 transition-colors duration-100">About</m.route.Link>
                            <m.route.Link href="/sources" onclick="return app.linkto('/sources')" class="2xs:w-72 2xs:mx-auto xs:w-60 xs:mx-0 sm:w-28 pt-1.5 pb-1.5 bg-gradient-to-b from-slate-900 via-sky-950 to-slate-900 border border-slate-500 text-slate-200 text-center text-[13px] font-[Montserrat] font-semibold tracking-wide rounded-lg hover:bg-gradient-to-b hover:from-slate-600 hover:via-transparent hover:to-slate-700 md:hover:to-slate-900 hover:text-slate-200 transition-colors duration-100">Sources</m.route.Link>
                            <a href="#" onclick="return false" class="2xs:w-72 2xs:mx-auto xs:w-60 xs:mx-0 sm:w-28 p-1.5 bg-gradient-to-b from-slate-900 via-sky-950 to-slate-900 border border-slate-500 text-slate-200 text-center text-[13px] font-[Montserrat] font-semibold tracking-wide border border-solid border-gray-600 rounded-lg hover:bg-gradient-to-b hover:from-slate-600 hover:via-transparent hover:to-slate-700 md:hover:to-slate-900 hover:text-slate-200 transition-colors duration-100 mt-1 md:mt-0 md:ml-1">Contribute</a>
                            </div>
                        
                    </div>
                </nav>

            </div>
            
        </>)
    }
}
